<template>
  <div class="study">
    <div
      class="container-fluid typeStyle type-fa_wlxljy"
      :style="{backgroundImage: 'url(' + require('@/assets/img/children/bg-fa_ggfwtx.jpg') + ')'
      }"
    >
      <div class="container">
        <div class="row moreDes-titBox">
          <h1 class="Tit">千舟教育学历注册中心</h1>
          <p class="moreDes">构建终身学习立交桥枢纽</p>
          <div class="clearfix">
            <a href="https://qzjy.net.cn/" class="to-more btnHover"
              >学历咨询<i class="c-alink"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid cont-nav">
      <div class="row cont-nav1">
        <div class="container">
          <div class="clearfix">
            <ul class="fl cont-navL">
              <li id="Anchor">
                <a class="cur">招生服务优势</a>
                <a class="">学生服务特色</a>
              </li>
            </ul>
            <div class="fr cont-navSel">
              <a>公共服务体系<i>&gt;</i></a>
              <div class="navSel">
                <a class="vbn">网络学历中心<i class="Down">&gt;</i></a>
                <ul class="cont-navList">
                  <li class="cont-navCur"><a>网络学历教育</a></li>
                  <li><a>赑风学习中心</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row cont-nav2"></div>
    </div>

    <div class="clearfix TW-box" id="a1">
      <div class="TW-Wrap TW-Wrap-li2">
        <div class="container-fluid">
          <!-- 图文/列表 TW -->
          <div class="row TW-list TWicon-wlxl TW-Scon">
            <div class="container-fluid TW-item clearfix bg-grey-s">
              <div class="container">
                <div class="xs12 xm6 xb5 TW-con">
                  <img
                    class="img-responsive"
                    src="../../../../assets/img/children/ggfwtx_pic01@2x.png?20210901140353"
                  />
                </div>
                <div class="xs12 xm6 xb7 xs0-move ImgDes">
                  <h4>
                    2007年，赑风科技发展有限公司成为现代远程教育公共服务体系试点单位，为远程教育试点高校提供全面支持服务，构建面向全民终身学习的数字化学习支持服务体系。目前，已与多所知名高校合作，提供综合、理工类等200余热门专业。在全国已建设53家赑风学习中心，为500多家企业定制人才提升方案。
                  </h4>
                  <p class="tc">
                    <a class="to-more btnHover"
                      >进入官网<i class="c-alink"></i
                    ></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="project information advantage" style="background: #f0f2f5">
      <div class="title">
        <h2>招生服务优势</h2>
        <i></i>
      </div>
      <div class="container" style="padding:0">
        <div class="list clearfix">
          <div class="item">
            <div class="qwe">
              <div class="blo-white-titbox">
                <i
                  class="ggfwtx"
                  :style="{backgroundImage: 'url(' + require('@/assets/img/children/jjfa2_ggfwtx01@2x.png') + ')'
                  }"
                ></i>
              </div>
              <div class="blo-white-con">
                <div class="blo-white-tit">标准化运营管理</div>
                <p class="blo-des">
                  学习中心建设，管理制度建设，管理平台建设，服务团队培养
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="qwe">
              <div class="blo-white-titbox">
                <i
                  class="ggfwtx"
                  :style="{backgroundImage: 'url(' + require('@/assets/img/children/CP-zhy_03@2x.png') + ')'
                  }"
                ></i>
              </div>
              <div class="blo-white-con">
                <div class="blo-white-tit">专业教育支持服务</div>
                <p class="blo-des">
                  优质的教学服务，全面的质量监控，便捷的沟通平台，丰富的学生活动
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="qwe">
              <div class="blo-white-titbox">
                <i
                  class="ggfwtx"
                  :style="{backgroundImage: 'url(' + require('@/assets/img/children/jjfa2_it_icon04@2x.png') + ')'
                  }"
                ></i>
              </div>
              <div class="blo-white-con">
                <div class="blo-white-tit">优质教育资源</div>
                <p class="blo-des">
                  20余所合作高校，10余个学科门类，200余热门专业，10000余门课程
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="qwe">
              <div class="blo-white-titbox">
                <i
                  class="ggfwtx"
                  :style="{backgroundImage: 'url(' + require('@/assets/img/children/qypx_02@2x.png') + ')'
                  }"
                ></i>
              </div>
              <div class="blo-white-con">
                <div class="blo-white-tit">先进技术支持</div>
                <p class="blo-des">
                  招生管理，学籍管理，督导管理，学务管理<br />&nbsp;
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="qwe">
              <div class="blo-white-titbox">
                <i
                  class="ggfwtx"
                  :style="{backgroundImage: 'url(' + require('@/assets/img/children/jjfa2_it_icon06@2x.png') + ')'}"
                ></i>
              </div>
              <div class="blo-white-con">
                <div class="blo-white-tit">名校与名企</div>
                <p class="blo-des">
                  优质资源共享，校企需求对接，共建培养基地，学历技能双提升
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="project information container characteristic">
      <div class="title">
        <h2>学生服务特色</h2>
        <i></i>
      </div>
      <div class="clearfix list mCon">
        <div class="item">
          <div
            class="asd"
            :style="{backgroundImage: 'url(' + require('@/assets/img/children/jjfa3_ggfw_pic01@2x.jpg') + ')'}"
            @mouseover="enter($event)"
            @mouseout="leave($event)"
          >
            <div class="thumb-item">
              <div class="thumb-imgbox">
                <i
                  class="ggfwtx"
                  :style="{backgroundImage: 'url(' + require('@/assets/img/children/FA-ggfwtx_01@2x.png') + ')'}"
                ></i>
                <div class="thumb-tit">一站式咨询服务</div>
              </div>
              <div class="thumb-con">
                <p class="thumb-info">
                  为学习者提供职业规划、学校选择、专业推荐、课程咨询、导学督学、就业指导等全程教育支持服务。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div
            class="asd"
            :style="{backgroundImage: 'url(' + require('@/assets/img/children/jjfa3_ggfw_pic02@2x.jpg') + ')'}"
            @mouseover="enter($event)"
            @mouseout="leave($event)"
          >
            <div class="thumb-item">
              <div class="thumb-imgbox">
                <i
                  class="ggfwtx"
                  :style="{backgroundImage: 'url(' + require('@/assets/img/children/FA-ggfwtx_02@2x.png') + ')'}"
                ></i>
                <div class="thumb-tit">实时助学服务</div>
              </div>
              <div class="thumb-con">
                <p class="thumb-info">
                  为学生提供网络实时答疑、在线讨论、电话答疑等助学服务，指导、帮助学生完成学习任务。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div
            class="asd"
            :style="{backgroundImage: 'url(' + require('@/assets/img/children/jjfa3_ggfw_pic03@2x.jpg') + ')'}"
            @mouseover="enter($event)"
            @mouseout="leave($event)"
          >
            <div class="thumb-item">
              <div class="thumb-imgbox">
                <i
                  class="ggfwtx"
                  :style="{backgroundImage: 'url(' + require('@/assets/img/children/FA-ggfwtx_03@2x.png') + ')'}"
                ></i>
                <div class="thumb-tit">实时助学服务</div>
              </div>
              <div class="thumb-con">
                <p class="thumb-info">
                  为学生提供网络实时答疑、在线讨论、电话答疑等助学服务，指导、帮助学生完成学习任务。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div
            class="asd"
            :style="{backgroundImage: 'url(' + require('@/assets/img/children/jjfa3_ggfw_pic04@2x.jpg') + ')'}"
            @mouseover="enter($event)"
            @mouseout="leave($event)"
          >
            <div class="thumb-item">
              <div class="thumb-imgbox">
                <i
                  class="ggfwtx"
                  :style="{backgroundImage: 'url(' + require('@/assets/img/children/FA-ggfwtx_04@2x.png') + ')'}"
                ></i>
                <div class="thumb-tit">一站式咨询服务</div>
              </div>
              <div class="thumb-con">
                <p class="thumb-info">
                  为学习者提供职业规划、学校选择、专业推荐、课程咨询、导学督学、就业指导等全程教育支持服务。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    enter(e) {
      e.currentTarget.className = "asd In";
    },
    leave(e) {
      e.currentTarget.className = "asd";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/header/children/study.scss";
</style>
<style lang="scss">
@import "../../../../assets/css/header/children/index.scss";
</style>
